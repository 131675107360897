<template>
  <v-dialog :value="dialog" persistent max-width="60%" max-height="60%" @click:outside="$emit('close')">
    <v-card>
      <v-card-title class="text-h5"> Memory Module </v-card-title>

      <span style="margin-left: 25px">Format: { "memory1": value, "memory2": value }</span>

      <v-checkbox style="margin-left: 25px" v-model="isNowSet" label="Include current date and time" @change="includeNow"></v-checkbox>
      <div class="px-5 my-5">
        <JsonEditorVue :mode="'text'" v-model="memory"/>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="updateMemory"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import JsonEditorVue from "json-editor-vue";
import cloneDeep from "lodash/cloneDeep";

const {
  mapActions: AIChatActions,
  mapGetters: AIChatGetters,
} = createNamespacedHelpers('AIChat');

export default {
  name: 'MemoryModal',
  components: {JsonEditorVue},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    memory: {
      type: Object,
    }
  },
  data() {
    return {
      isNowSet: true,
    }
  },
  watch: {
    dialog() {
      if(!this.memory.now) {
        this.isNowSet = false;
      } else {
        this.isNowSet = true;
      }
    }
  },
  computed: {
    ...AIChatGetters({
      edit: 'GET_EDIT_SESSION',
    }),
  },
  methods: {
    ...AIChatActions(['updateSession']),
    includeNow(value) {
      let memoryData = this.memory;
      memoryData ??= {};
      if(typeof memoryData === 'string') {
        memoryData= JSON.parse(memoryData);
      }
      memoryData['now'] = value;
      if(value) {
        memoryData['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else {
        delete memoryData['timezone'];
      }
      this.memory = cloneDeep(memoryData);
    },
    async updateMemory() {
      this.$emit('memoryChanged', this.memory)
      this.$emit('close');
    },
  },
  mounted() {
    if(!this.memory.now) {
      this.isNowSet = false;
    }
  },
  created() {
    if(!this.memory.now) {
      this.isNowSet = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './memory-modal';
</style>
