<template>
  <v-dialog :value="dialog" persistent max-width="700" @click:outside="$emit('close')">
    <v-card>
      <v-card-title class="text-h5"> Add Tools </v-card-title>
      <v-text-field label="Search" @input="debounceInput" style="margin-left: 25px; margin-right: 25px; margin-top: 10px"></v-text-field>
      <div class="checkboxContainer">
        <div>
          <v-checkbox
              class="ma-0"
              justify="center"
              v-for="(item) in this.filteredTools"
              :key="item.id"
              :value="item.id.toString()"
              :id="item.id.toString()"
              v-model="toolSetIds"
              :label="item.name"
          >
          </v-checkbox>
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="$emit('close')"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";

const {
  mapActions: AIChatActions,
  mapGetters: AIChatGetters,
} = createNamespacedHelpers('AIChat');
const {  mapGetters: toolsGetters, mapActions: toolsActions, mapMutations: toolsMutations } =
    createNamespacedHelpers('promptsTools');

export default {
  name: 'ToolsSelect',
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    toolSets: {
      type: Array,
    },
  },
  data() {
    return {
      toolSetIds: [],
      filteredTools: [],
    };
  },
  watch: {
    toolSetIds: {
      immediate: true,
      handler(newVal) {
        const tools = this.Tools.filter((item) => {
          return newVal.includes(item.id.toString());
        });
        this.$emit('toolsChanged', tools)
      }
    },
    Tools: {
      handler(newVal) {
        this.filteredTools = newVal
      }
    },
    dialog() {
      const toolSets = cloneDeep(this.toolSets)
      this.toolSetIds = toolSets?.map((tool) => tool.id.toString()) || [];
    }
  },
  computed: {
    ...toolsGetters({
      Tools: 'TOOLS',
      fetchToolsLoading: 'fetchToolsLoading',
    }),
    ...AIChatGetters({
      createToolSetLoading: 'CREATE_TOOL_SET_LOADING',
      edit: 'GET_EDIT_TOOL_SET'
    })
  },
  methods: {
    ...AIChatActions(['updateToolSet']),
    ...toolsActions(['fetchTools']),
    ...toolsMutations({
      setTools: 'SET_TOOLS'
    }),
    async updateToolSet() {
      this.$emit('close');
    },
    async filterTools(search) {
      this.filteredTools = this.Tools.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
    },
    debounceInput: debounce(function (e) {
      this.search = e;
      this.filterTools(this.search);
    }, 100)
  },
  async created() {
    await this.fetchTools();
    this.filteredTools = this.Tools;
  }
};
</script>

<style lang="scss" scoped>
@import 'tools-select';
</style>
