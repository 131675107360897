<template>
  <div class="WorkspacePrompts" v-if="!workspacePromptsLoading && workspacePrompts.length > 0">
    <v-row>
      <div class="WorkspacePrompts__listContainer">
        <div
            :class='workspacePrompt.id === selectedPrompt?.id ? "WorkspacePrompts__listItemSelected" : "WorkspacePrompts__listItem" '
            v-for="workspacePrompt in workspacePrompts"
            :key="workspacePrompt.id"
            @click.stop="usePrompt(workspacePrompt)">
          <div class="itemSection">
            <span class="description">{{ workspacePrompt.prompt }}</span>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: workspacesGetters, mapActions: workspaceActions, mapMutations: workspaceMutations } = createNamespacedHelpers('workspace');
const { mapGetters: AIChatGetters } = createNamespacedHelpers('AIChat');

import * as moment from "moment";
import lodashGet from "lodash/get";

export default {
  name: 'WorkspacePrompt',
  components: {},
  data() {
    return {
      search: '',
      isCreateDialogOpen: false,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
      user: 'USER'
    }),
    ...AIChatGetters({
      chatWorkspace: 'GET_CHAT_WORKSPACE',
    }),
    ...workspacesGetters({
      workspacePrompts: 'WORKSPACE_PROMPTS',
      workspacePromptsLoading: 'IS_WORKSPACE_PROMPTS_LOADING',
      workspaceActionLoading: 'IS_WORKSPACE_PROMPTS_ACTION_LOADING',
      selectedPrompt: 'SELECTED_WORKSPACE_PROMPT',
    }),
  },
  watch: {
    'chatWorkspace.id'() {
      this.getWorkspacePrompts({
        workspaceId: this.chatWorkspace?.id
      })
    }
  },
  methods: {
    ...workspaceActions(['getWorkspacePrompts']),
    ...workspaceMutations({
      setSelectedPrompt: 'SET_SELECTED_WORKSPACE_PROMPT',
    }),
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    getTime(date) {
      return moment(date).fromNow()
    },
    usePrompt(prompt) {
      if(this.selectedPrompt?.id === prompt?.id) {
        this.setSelectedPrompt(null);
        return;
      }
      this.setSelectedPrompt(prompt);
      this.$emit('onPromptSelect', { prompt: prompt.prompt })
    }
  },
  mounted() {
    if(this.chatWorkspace?.id) {
      this.getWorkspacePrompts({
        workspaceId: this.chatWorkspace.id
      })
    }
  },
  beforeDestroy() {
    this.setSelectedPrompt(null);
  }
};
</script>

<style lang="scss" scoped>
@import './workspace-prompts';
</style>
